/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from '@emotion/styled';
import TrainingRoomJPG from '@assets/images/training-room.jpg';
import TrainingRoomMobileJPG from '@assets/images/training-room-mobile.jpg';
import SabineJPG from '@assets/images/sabine-mobile.jpg';
import OchsJPG from '@assets/images/ochs-mobile.jpg';
import KellerJPG from '@assets/images/keller-mobile.jpg';
import SauerJPG from '@assets/images/sauer-mobile.jpg';
import SchledererJPG from '@assets/images/schlederer-mobile.jpg';
import YoungJPG from '@assets/images/young-mobile.jpg';
import RöverJPG from '@assets/images/röver-mobile.jpg';
import JanczikJPG from '@assets/images/turewicz-mobile.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import CVSabine from '../../components/atoms/CV/CVSabine/CVSabine';
import CVOchs from '../../components/atoms/CV/CVOchs/CVOchs';
import CVKeller from '../../components/atoms/CV/CVKeller/CVKeller';
import CVSauer from '../../components/atoms/CV/CVSauer/CVSauer';
import CVSchlederer from '../../components/atoms/CV/CVSchlederer/CVSchlederer';
import CVRöver from '../../components/atoms/CV/CVRöver/CVRöver';
import CVYoung from '../../components/atoms/CV/CVYoung/CVYoung';
import Person from '../../components/molecules/Person/Person';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';
import { useTranslationContext } from '~/pages/team';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/team';

const TeamsPage: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();
  const { i18n } = useTranslation();

  return (
    <>
      <HeaderFooterLayout
        desktopHero={TrainingRoomJPG}
        mobileHero={TrainingRoomMobileJPG}
        altHero="Team Raum"
        t={t}
        deLink="/team/"
        enLink="/en/team/"
      >
        <TeamContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: t('TeamContent1') || tEn('TeamContent1'),
            }}
          />
          <Person src={SabineJPG} name="Dr. med. Sabine Uez" caret>
            <CVSabine />
          </Person>
          <Person src={OchsJPG} name="Prof. Dr. med. Günter Ochs" caret>
            <CVOchs />
          </Person>
          <Person
            src={KellerJPG}
            name="Prof. Dr. Dipl. Psych. Ingo Keller"
            caret
          >
            <CVKeller />
          </Person>
          <Person src={YoungJPG} name="Prof. Dr. Young" caret>
            <CVYoung />
          </Person>
          <Person
            src={SauerJPG}
            name={
              i18n.language === 'de'
                ? 'Carola Sauer, Psychologin M.Sc.'
                : 'Carola Sauer, Psychologist M.Sc.'
            }
            caret
          >
            <CVSauer />
          </Person>
          <Person src={SchledererJPG} name="Dr. med. Senka Schlederer" caret>
            <CVSchlederer />
          </Person>
          <Person
            src={JanczikJPG}
            name={
              i18n.language === 'de'
                ? 'Kamila Janczik | Praxisassistentin'
                : 'Kamila Janczik | Practice Assistant'
            }
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('TeamContent2') || tEn('TeamContent2'),
            }}
          />
          <Person src={RöverJPG} name="Dr. med. Julia Röver" caret>
            <CVRöver />
          </Person>
          <Person
            src={JanczikJPG}
            name={
              i18n.language === 'de'
                ? 'Kamila Janczik | Praxisassistentin'
                : 'Kamila Janczik | Practice Assistant'
            }
          />
        </TeamContainer>
      </HeaderFooterLayout>
    </>
  );
};

const TeamContainer = styled.div`
  @media (min-width: 1200px) {
    margin: 5rem;
  }

  h1,
  h2 {
    margin: 1rem;
  }
`;

export default TeamsPage;
